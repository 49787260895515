import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
  DropdownButton,
  FloatingLabel,
  Dropdown,
  Table,
  Alert
} from 'react-bootstrap'

import { useForm } from 'react-hook-form'
import { CONFIG } from '../../config/constant'
import { BASE } from '../../basedata/constants'
import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import './visitorCreate.sass'

registerLocale('pt-br', ptBR)

import 'react-datepicker/dist/react-datepicker.css'
import { useParams } from 'react-router-dom'
import { fetchWithApiKey } from '../../basedata/apiCall'

const maskCPF = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

// (00) 00000-0000
const maskPhone = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d{4})(\d)/, '$1-$2')
}

// 00000-000
const maskCEP = (value: any) => {
  return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2')
}

// 00/00/0000
const maskDate = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1')
}

const isValidEmail = (email: any) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )

const strongPassword = (password: any) => {
  return /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(
    password
  )
}

export function UserEdit() {
  let { id } = useParams()

  if (id === 'me') {
    id = sessionStorage.getItem('token') || 'notfound'
  }

  console.log(id)

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    getValues,
    reset
  } = useForm()

  const [userData, setUserData] = useState<{ [key: string]: any }>({})

  const [hasToken, setHasToken] = useState('')

  const [disableSign, setDisableSign] = useState(false)

  const [userUpdated, setUserUpdated] = useState(false)

  const role = sessionStorage.getItem('role')

  const [passwordRules, setPasswordRules] = useState(true)
const [isLoading, setLoading] = useState(false)
  const currentUser = sessionStorage.getItem('role')
  const onSubmit = (data: any) => {

    setUserUpdated(false)

    if (!strongPassword(data.password)) {
      setError('password', {
        type: 'manual',
        message: 'Você precisa informar uma data válida'
      })
      return false
    }

    if (data.password && data.password !== data.repassword) {
      setError('password', {
        type: 'manual',
        message: 'Você precisa informar uma data válida'
      })

      return false
    }
    setLoading(true)
    fetchWithApiKey(`${BASE.api.base_url}${BASE.api.access_edit}/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': BASE.api.api_key
      },
      body: JSON.stringify({
        id: id,
        name: data.name || userData.name,
        email: data.email || userData.email,
        password: data.password || null,
        role: data.type || 'default'
      })
    })
      .then((res) => res.json())
      .then((response) => {
        setUserUpdated(true)
        setLoading(false)
        setTimeout(() => {
          setUserUpdated(false)
        }, 3000)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getUserToEdit = () => {
    fetchWithApiKey(`${BASE.api.base_url}${BASE.api.access_find}/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': BASE.api.api_key
      }
    })
      .then((res) => res.json())
      .then((response) => {
        setUserData(response)
        console.log(response)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    getUserToEdit()
  }, [])

  return (
    <Card>
      <Card.Header>
        <Card.Title as="h5">Editar Usuário</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <Form onSubmit={handleSubmit(onSubmit)}>
              {currentUser !== 'default' && (
                <Row className="mb-3">
                  
                  {isLoading &&
                    <div className='local-loader'>
                      <i
                        className="fa fa-spinner fa-spin text-c-green f-10 m-r-15"
                        title="oiee"
                      />
                    </div>}
                  <Col>
                    <Form.Label>Login</Form.Label>
                    <Form.Control disabled autoComplete="off"
                      defaultValue={userData.login}
                      value={userData.login}
                      placeholder="login"
                      {...register('login')}
                    />
                  </Col>
                </Row>
              )}
              {currentUser !== 'default' && (
                <Row className="mb-3">
                  <Col>
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                      defaultValue={userData.name}
                      placeholder="Nome"
                      {...register('name')}
                    />
                  </Col>

                  <Col>
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control
                      defaultValue={userData.email}
                      type="email"
                      placeholder="E-mail"
                      {...register('email')}
                    />
                    {errors.email && (
                      <span className="label-error">
                        Você precisa informar um E-mail válido
                      </span>
                    )}
                  </Col>
                </Row>
              )}
              <Row className="mb-5">
                <Col>
                  <Form.Label>Senha</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Senha"
                    {...register('password')}
                  />
                </Col>
                <Col>
                  <Form.Label>Confirmar Senha</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirmar Senha"
                    {...register('repassword')}
                  />
                </Col>
                {errors.password && (
                  <span className="label-error">
                    As senhas precisam ser iguais e sequir os requisitos abaixo:
                  </span>
                )}

                <ul className="password-rules">
                  <li>• O tamanho da senha deve ser maior ou igual a 8</li>
                  <li>
                    • A senha deve conter um ou mais caracteres maiúsculos
                  </li>
                  <li>
                    • A senha deve conter um ou mais caracteres minúsculos
                  </li>
                  <li>• A senha deve conter um ou mais valores numéricos</li>
                  <li>• A senha deve conter um ou mais caracteres especiais</li>
                </ul>
              </Row>
              {/* <Row className='mb-3'>
                <Col sm={1}>
                  <Form.Control
                    defaultValue={userData.name}
                    placeholder="Nome"
                    type='checkbox'
                    className='check-box-interno'
                    {...register('name')}
                  />
                </Col>
                <Col sm={2}>
                  <Form.Label>Bloquear?</Form.Label>
                </Col>
              </Row> */}
              <Row>
                <Col>
                  <Form.Group>
                    <Button
                      disabled={disableSign}
                      type="submit"
                      className="mb-0 w-100"
                      onClick={() => clearErrors()}
                    >
                      Editar Usuário
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
              {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}
            </Form>
          </Col>
        </Row>

        {userUpdated && (
          <Alert className="mt-5" variant="success">
            Usuário Atualizado!!
          </Alert>
        )}
      </Card.Body>
    </Card>
  )
}
