import React, { useState } from 'react'
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
  DropdownButton,
  Dropdown,
  Table,
  FormSelect
} from 'react-bootstrap'
import './findUserFull.sass'

import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import avatar3 from '../../assets/images/user/avatar-3.jpg'

import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { CONFIG } from '../../config/constant'
import { BASE } from '../../basedata/constants'

import { useForm } from 'react-hook-form'
import { fetchWithApiKey } from '../../basedata/apiCall'

export function VisitorReport() {
  const [userList, setUserList] = useState([])

  const [userSearch, setUserSearch] = useState({
    identifier: '',
    type: 'externalId'
  })

  const [accessList, setAccessList] = useState([
    {
      name: 'FRaNCISCO GessiNi',
      access_code: 'A00123001X',
      token: '$2a$10$XXVjYUqkFU8kNHH/pAmua.7QCcLuKpzlTY75uAoYZcri8uJioJ74i',
      externalId: '123',
      hikId: 'e4ba336c90284c0db3048f5b07ce4ecb',
      biometric: false,
      gate: 'Matarazzo',
      flow: 'entrance',
      userAvatar: null
    },
    {
      name: 'User Test',
      access_code: 'A00123001X',
      token: '$2a$10$XXVjYUqkFU8kNHH/pAmua.7QCcLuKpzlTY75uAoYZcri8uJioJ74i',
      externalId: '123',
      hikId: 'e4ba336c90284c0db3048f5b07ce4ecb',
      biometric: false,
      gate: 'Palestra',
      flow: 'exit',
      userAvatar: null
    }
  ])

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    getValues
  } = useForm()
  const [dateTo, setDateTo] = useState(new Date())
  const [dateFrom, setDateFrom] = useState(new Date())

  const [reportType, setReportType] = useState('access')

  const [downloadFile, setDownloadFile] = useState('')

  const onSubmit = (data: any) => {
    fetchWithApiKey(`${BASE.api.base_url}${BASE.api.visitor_report}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: CONFIG.API_KEY
      },
      body: JSON.stringify({
        type: reportType,
        dateTo: dateTo,
        dateFrom: dateFrom
      })
    })
      .then((resg) => resg.json())
      .then((response) => {
        setDownloadFile(response.file)
        setUserList(response.visitors)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const removeBiometry = (userId: any, userData: any) => {
    if (!confirm('Apagar Biometria?')) {
      return false
    }
    fetchWithApiKey(`${BASE.api.base_url}${BASE.api.remove_biometry}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: CONFIG.API_KEY
      },
      body: JSON.stringify({
        user: userId
      })
    })
      .then((resg) => resg.json())
      .then((response) => {
        onSubmit({
          type: userData[0],
          identifier: userData[1]
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const removeUserData = (data: any) => {
    fetchWithApiKey(`${BASE.api.base_url}${BASE.api.find_user_with_biometry}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: CONFIG.API_KEY
      },
      body: JSON.stringify({
        userId: data.identifier,
        userSearchType: data.type
      })
    })
      .then((resg) => resg.json())
      .then((response) => {
        console.log('oieee')
        setUserList(response)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <Card className="Recent-Users">
      <Card.Header>
        <Card.Title as="h5">Relatório de visitantes</Card.Title>
        <FormSelect
          className="form-control"
          style={{ width: '200px' }}
          onChange={(event) => {
            const { value } = event.target
            setReportType(value)
          }}
        >
          <option value="access">Acesso</option>
          <option value="signup">Cadastro</option>
        </FormSelect>
      </Card.Header>
      <Card.Body className="">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3">
            <Col>
              <Form.Label>Período de:</Form.Label>
              <DatePicker
                className="form-control"
                locale="pt-br"
                dateFormat="dd/MM/yyyy"
                selected={dateFrom}
                onChange={(date: Date) => setDateFrom(date)}
              />
            </Col>
            <Col>
              <Form.Label>Período até:</Form.Label>
              <DatePicker
                className="form-control"
                locale="pt-br"
                dateFormat="dd/MM/yyyy"
                selected={dateTo}
                onChange={(date: Date) => setDateTo(date)}
              />
            </Col>
            <Col md={2}>
              <Form.Label>{' .'}</Form.Label>
              <input
                className="btn form-control btn-primary"
                type="submit"
                value="Pesquisar"
                onClick={() => clearErrors()}
              />
            </Col>
            <Col md={2}>
              <Form.Label className="align-center">.</Form.Label>
              <input
                className="btn form-control btn-default"
                type="submit"
                value="Baixar"
                onClick={() => console.log(downloadFile)}
              />
            </Col>
          </Row>
        </Form>

        <Table responsive hover>
          <tbody>
            {accessList &&
              accessList.map((user: any) => (
                <tr key={user.hikiId}>
                  <td className="user-image">
                    <img
                      className="rounded-circle"
                      style={{ width: '40px' }}
                      src={
                        user.userAvatar
                          ? `${BASE.api.base_url}/biometricImages/${user.userAvatar}`
                          : avatar1
                      }
                      alt="activity-user"
                    />
                  </td>
                  <td className="access-detail">
                    <h6 className="mb-1">{user.name}</h6>
                    <p className="m-0">{user.gate}</p>
                  </td>
                  <td className="access-detail">
                    <h6 className="mb-1">Horário</h6>
                    <p className="m-0">10/10/2022 10:30</p>
                  </td>
                  <td className="access-detail">
                    <h6 className="mb-1">Aprovador:</h6>
                    <p className="m-0">Fulano DeTal</p>
                  </td>
                  <td>
                    {user.flow === 'entrance' ? (
                      <i className="fa fa-caret-up f-22 m-r-10 text-c-green" />
                    ) : (
                      <i className="fa fa-caret-down f-22 m-r-10 text-c-red" />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}
